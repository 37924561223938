import React, { useState } from 'react';
import { Link } from 'gatsby';
import Scroll from './Scroll';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default function Nav({ onMenuToggle = () => {} }) {
  const [email, updateEmail] = useState('');
  const [submitted, updateSubmitStatus] = useState(false);
  const _handleChange = e => {
    updateEmail(e.target.value);
  };
  const _handleSubmit = async e => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    console.log(result);
    updateSubmitStatus(result);
    // I recommend setting `result` to React state
    // but you can do whatever you want
  };
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
          <div id="menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/About">About Me</Link>
              </li>
              <li>
                <Link to="/Teaching">Teaching</Link>
              </li>
              <li>
                <Link to="/Scheduling">Scheduling</Link>
              </li>
              <li>
                <a href="https://summerstringsforgirls.com/">
                  Summer Strings Academy
                </a>
              </li>

              <li>
                <Link to="/Listen">Listen</Link>
              </li>
              <li>
                <Link to="/Gallery">Gallery</Link>
              </li>
              <li style={{ marginTop: '50px' }}>
                <p
                  style={{ marginBottom: '0', paddingBottom: '0', top: '1em' }}
                >
                  Subscribe For More Info
                </p>
                {!submitted || submitted.result === 'error' ? (
                  <form className="subscribe-form" onSubmit={_handleSubmit}>
                    <input
                      value={email}
                      onChange={_handleChange}
                      placeholder="Enter your email"
                      required
                    />
                    <br />
                    <div style={{ whiteSpace: 'normal' }}>
                      {submitted.msg && (
                        <p style={{ lineHeight: '2em' }}>{submitted.msg}</p>
                      )}
                    </div>
                    <div>
                      <input type="submit" value="Submit" />
                    </div>
                  </form>
                ) : (
                  <p>{submitted.msg}</p>
                )}
              </li>
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
