module.exports = {
  siteTitle: 'Melanie Riordan - Violinist | Violin Teacher | Violin Lessons', // <title>
  manifestName: 'Melanie Riordan - Violinist | Violin Teacher | Violin Lessons',
  manifestShortName: 'Melanie', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#4E8684',
  manifestThemeColor: '#4E8684',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  heading: 'Melanie Riordan',
  subHeading: 'Concert violinist and passionate educator',
  // social
  socialLinks: [
    {
      style: 'solid',
      icon: 'fa-phone',
      name: 'Phone',
      url: 'tel:+19145843358',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/melannie.rio/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:melanie.anne.riordan@gmail.com',
    },
  ],
};
